import Numbers from '@/common/mixins/Numbers';

export default {
  mixins: [Numbers],
  name: 'AnnotationFormMesures',
  props: {
    superficie: {
      type: Number,
      default: 0
    },
    perimetre: {
      type: Number,
      default: 0
    },
    rayon: {
      type: Number,
      default: 0
    }
  }
}
