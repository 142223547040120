import {MapElementFactory} from 'vue2-google-maps'
import _ from 'lodash'
import * as turf from '@turf/turf'
import Colors from '../../common/mixins/Colors.js'

export default MapElementFactory({
  name: 'geojsonLayerStandard',
  data() {
    return {
      data: null,
      statuses_parcels: [],
      style_default: {
        fillColor: '#987654',
        fillOpacity: 0.5,
        strokeColor: '#123456',
        strokeWeight: 2,
        strokeOpacity: 0.5,
        zIndex: 200,
        display: true,
        clickable: false
      },
      sourcing_styles: {},
      geoJsonData: null,
      autoZoomFromMicrotime: null,
    }
  },
  ctr: () => google.maps.Data,
  //// The following is optional, but necessary if the constructor takes multiple arguments
  //// e.g. for GroundOverlay
  // ctrArgs: (options, otherProps) => [options],
  events: [],
 
  // Mapped Props will automatically set up
  //   this.$watch('propertyName', (v) => instance.setPropertyName(v))
  //
  // If you specify `twoWay`, then it also sets up:
  //   google.maps.event.addListener(instance, 'propertyName_changed', () => {
  //     this.$emit('propertyName_changed', instance.getPropertyName())
  //   })
  //
  // If you specify `noBind`, then neither will be set up. You should manually
  // create your watchers in `afterCreate()`.
  mappedProps: {
    // routeIndex: { type: Number },
    // options: { type: Object },
    // panel: { },
    // directions: { type: Object },
    //// If you have a property that comes with a `_changed` event,
    //// you can specify `twoWay` to automatically bind the event, e.g. Map's `zoom`:
    // zoom: {type: Number, twoWay: true}
  },
  // Any other properties you want to bind. Note: Must be in Object notation
  props: {
    name: {type: String, default:'default'},
    geoJson: null,
    zIndex: null,
    autozoom: false,
    autozoomInitial: false,
    maxautozoom: { type: Number, default: 50 },
    autozoomWithCompany: { type: Boolean, default: false },
    opacity: { type: Number, default: null },
    clickable: null,
  },
  // Actions you want to perform before creating the object instance using the
  // provided constructor (for example, you can modify the `options` object).
  // If you return a promise, execution will suspend until the promise resolves
  beforeCreate (options) {},

  // Actions to perform after creating the object instance.
  afterCreate (dataInstance) {

    //AS--> Stock l'instance de maps.data
    this.dataInstance = dataInstance;

    //AS--> Evénement de clic sur une feature
    if(this.isClickable){
      this.dataInstance.addListener('click', this.onClick);
      this.dataInstance.addListener('mouseover', this.onMouseover);
      this.dataInstance.addListener('mouseout', this.onMouseout);
    }

    //AS--> Récupère les statuts des parcelles
    if(this.isParcelle()){
      this.statuses_parcels = this.$store.getters.getStatuses('parcels')
    }

    this.draw(true);


  },

  watch:{
    opacity(after, before){ //AS--> Ne fonctionne pas !!!!!!
      console.log(after, before);
    },
    "$store.getters.areMapToolsActive"(after, before){
      console.log('GeojsonLayerStandard - areMapToolsActive - watcher')
      this.updateStyle()
    },
    "$store.getters.searches_getLayersFeatures"(after, before){ //AS--> Pour garder l'écouteur actif sur les changements de calques de filtre
      if(this.name == 'layers_lieu'){
        console.log('GeojsonLayerStandard - layers_lieu - watcher')
        this.draw()
      }
    },
    "$store.state.searches.preview"(after, before){ //AS--> Pour garder l'écouteur actif sur les changements de calques de preview
      if(this.name == 'layers_preview'){
        console.log('GeojsonLayerStandard - layers_preview - watcher')
        this.draw()
      }
    },
    //AS--> Surveille les changements de droits d'accès au clique sur les calques
    "$store.getters.canClickOnLayers" : function(after){
      console.log('GeojsonLayerStandard - canClickOnLayers - watcher')
      this.updateStyle()
    },
    "$store.state.parcelle.parcelles_geojson" : function(after){
      if(['parcelles'].includes(this.name)){
        console.log('GeojsonLayerStandard - parcelles_geojson - watcher', this.name)
        this.draw()
      }
    },
    "$store.state.parcelle.parcelles_list" : function(after, before){
      //AS--> S'il y a du contenu qui vient de changer dans parcelles_liste, on redessine les parcelles
      if(after && this.name == 'parcelles_liste'){
        console.log('GeojsonLayerStandard - parcelles_list - watcher')
        this.draw(false, true)
      }
      //AS--> Si on vient de vider parcelles_liste, on redessine les parcelles
      if(before && !after && this.name == 'parcelles'){
        this.draw()
      }
    },
    "$store.state.parcelle.parcelle_on_id" : function(after, before){
      if(['parcelle'].includes(this.name)){
        console.log('GeojsonLayerStandard - parcellle_on_id - watcher')
        this.draw(false, true)
      }
      if(['parcelles', 'parcelles_liste'].includes(this.name)){
        if(after != before){
          this.updateStyle()
        }
      }
    },
    "$store.state.parcelle.parcelle_on" : function(after, before){
      if(['parcelle'].includes(this.name)){
        console.log('GeojsonLayerStandard - parcellle_on - watcher ' + this.name)
        this.updateStyle()
      }
    },
    "$store.state.parcelle.parcelle_on_detailled" : function(after, before){
      if(['parcelle'].includes(this.name)){
        console.log('GeojsonLayerStandard - parcelle_on_detailled - watcher ' + this.name)
        this.updateStyle()
      }
    },
    "$store.state.ui.statuses" : function(after, before){
      if(['parcelles', 'parcelles_liste'].includes(this.name)){
        console.log('GeojsonLayerStandard - statuses - watcher')
        this.statuses_parcels = after.parcels
        this.draw(false, true)
      }
    },
  },

  computed:{
    isClickable(){
      return this.$store.getters.canClickOnLayers && (typeof this.clickable == 'undefined' || this.clickable === null || this.clickable === true)
    },
  },

  methods: {

    draw(init, block_autozoom = false){
      let self = this

      if(this.geoJsonData){
        this.geoJsonData.forEach(function(feature){
          self.dataInstance.remove(feature);
        });
      }
       
      if(this.geoJson?.features && this.geoJson.features.length){

        if(self.name == 'layers_lieu') console.log('layers_lieu - drawer', self.geoJson.features.length)
        if(self.name == 'layers_preview') console.log('layers_preview - drawer', self.geoJson)

        //AS--> Redéfini le style par défaut s'il est précisé dans le geoJson
        if(this.geoJson.properties && this.geoJson.properties.style){        //AS--> Format 1
          this.style_default = _.assign(_.cloneDeep(this.style_default), this.geoJson.properties.style);
        }
        if(this.geoJson.style){                                              //AS--> Format 2
          this.style_default = _.assign(_.cloneDeep(this.style_default), this.geoJson.style);
        }

        //AS--> Si le zoom doit prendre en compte le point de l'entreprise en cours de visualisation, on l'ajoute de façon transparente aux features
        if(self.autozoomWithCompany && this.geoJson.features){
          this.geoJson.features.push({
              "type": "Feature",
              "geometry": {
                  "type": "Point",
                  "coordinates": [
                      self.$store.state.ui.map_pin_pointed['lng'],
                      self.$store.state.ui.map_pin_pointed['lat']
                  ]
              },
              "properties": {
                  "style": {
                      "icon": false
                  }
              }
          })

        }
        
        //AS--> Défini les styles par défaut
        // this.dataInstance.setStyle(this.style_default);
        this.dataInstance.setStyle((feature) => this.getFeatureStyle(feature));

        //AS--> Récupère les features interprétées par GoogleMap après les avoir injectées sur la carte
        this.geoJsonData = this.dataInstance.addGeoJson(this.geoJson);

        //AS--> Redéfini les styles de chaque feature
        // this.updateStyle()

        if(!block_autozoom && (init !== true || this.autozoomInitial)){
          let autozoom    = this.autozoom
          let maxautozoom = this.maxautozoom
          if(this.geoJson.properties?.maxautozoom) maxautozoom = this.geoJson.properties.maxautozoom
          if(typeof this.geoJson.autozoom != 'undefined') autozoom = this.geoJson.autozoom

          if(autozoom){
            let bounds = turf.bbox(this.geoJson)
            bounds = {
              east: bounds[2],
              north: bounds[3],
              south: bounds[1],
              west: bounds[0]
            }

            let microtime = Date.now()
            this.autoZoomFromMicrotime = microtime

            setTimeout(function(){

              if(self.autoZoomFromMicrotime == microtime){
                self.dataInstance.map.fitBounds(bounds)
                //AS--> Rétabli un niveau de zoom maximum si le zoom auto a été trop loin
                if(self.dataInstance.map.getZoom() > maxautozoom) self.dataInstance.map.setZoom(maxautozoom)
              }
            }, 200)

          }
        }

      }

    },

    updateStyle(){
      let self = this
      //AS--> Redéfini les styles de chaque feature
      if(this.geoJsonData && this.geoJsonData.length){
        this.geoJsonData.forEach(function(feature){
          self.dataInstance.overrideStyle(feature, self.getFeatureStyle(feature))
        })
      }
    },

    //AS--> Fonction de clic sur une feature
    onClick(e){
      let self = this;
      let feature = e.feature;

      //AS--> Cas d'une parcelle
      if(self.isClickable && this.$store.state.ui.sourcing.parcelles_clicables && self.isParcelle(feature)){

        //AS--> Retire le mode fullscreen pour afficher la parcelle          
        if(self.$store.state.ui.full_screen) self.$store.dispatch('toggle_full_screen')

        let identifiant = this.getParcelleId(feature)
        //AS--> Déclare la feature comme étant en on
        this.$store.dispatch('setParcelleOnId', identifiant);

      }


    },

    //AS--> Fonction de mouse over sur une feature
    onMouseover(e){
      let self = this;
      let feature = e.feature;

      //AS--> Cas d'une parcelle
      if(self.isClickable && this.$store.state.ui.sourcing.parcelles_clicables && ['parcelles', 'parcelles_liste', 'parcelle'].includes(this.name)){

        //AS--> Déclare la feature comme étant en over
        this.$store.dispatch('setFeatureOver', feature);

        if(['parcelles', 'parcelles_liste'].includes(this.name)){
          //AS--> Passe la feature en style over
          let style = this.getFeatureStyle(feature)
          let over_style = this.getOverStyle(style)
          self.dataInstance.overrideStyle(feature, over_style)
        }

      }
      
    },

    getOverStyle(style){
      style.strokeOpacity = style.strokeOpacity+0.4
      style.fillOpacity = style.fillOpacity+0.4
      style.zIndex = style.zIndex+1
      return style
    },

    //AS--> Fonction de mouse out sur une feature
    onMouseout(e){
      let self = this;
      let feature = e.feature;

      //AS--> Cas d'une parcelle
      if(self.isClickable && this.$store.state.ui.sourcing.parcelles_clicables && self.isParcelle(feature)){

        //AS--> Retire la parcelle du focus
        this.$store.dispatch('setFeatureOver', null);

        //AS--> Remet le style normal
        let parcelle_id = this.getParcelleId(feature)
        if(self.$store.state.parcelle.parcelle_on_id != parcelle_id){
          let out_style = self.getFeatureStyle(feature)

          self.dataInstance.overrideStyle(feature, out_style);
        }
      }
      
    },

    isParcelle(){
      return ['parcelle', 'parcelles', 'parcelles_liste'].includes(this.name)
    },

    getParcelleId(feature){
      return feature.getProperty('idu')
    },

    getFeatureStyle(feature){
      let self = this;
      let style = feature.getProperty('style')
      if(typeof style == 'undefined') style = {}

      style = _.assign(_.cloneDeep(self.style_default), style)

      //AS--> zIndex
      if(self.zIndex && !style.zIndex) style.zIndex = self.zIndex;

      //AS--> Mode pointillés
      if(style.dashLength && style.dashSpace){
        style.icons = [{
          icon: {
            path: "M0 0 v "+style.dashLength,
            strokeOpacity: style.strokeOpacity,
            strokeWeight: style.strokeWeight,
            scale: 1,
          },
          offset: 0,
          repeat: (style.dashLength+style.dashSpace+style.strokeWeight)+"px"
        }]
        style.strokeOpacity = 0
      }


      //AS--> Icon
      let icon_url = '/theme/annuaire/img/point_bleu.png'
      if(style && style.icon && typeof style.icon == 'string') icon_url = style.icon.substring(0, 4) != 'http' ? '/sourcing_icons/'+style.icon : style.icon
      if((style && typeof style.icon == 'boolean' && style.icon === false) || (feature.getGeometry().getType() != 'Point')){
        icon_url = '/theme/annuaire/img/point_invisible.png'
      } else {
        style.fillOpacity = 0;
        style.strokeOpacity = 0;
      }

      let anchor   = icon_url == '/theme/annuaire/img/point_bleu.png' ? new google.maps.Point(6,6) : new google.maps.Point(18,18)
      style.zIndex = icon_url == '/theme/annuaire/img/point_bleu.png' ? this.zIndex+500 : this.zIndex

      if(style && style.anchor) anchor   = new google.maps.Point(style.anchor[0], style.anchor[1])

      style.icon = {
        anchor: anchor,
        url: icon_url
      }

      //AS--> Vérifie si une opacité est définie au niveau component, si on n'a pas d'icon
      if(!style.icons && this.opacity != null && typeof this.opacity != 'undefined'){
        let fillOpacity = this.opacity/100
        
        let strokeOpacity = fillOpacity * 1.3
        if(strokeOpacity > 1) strokeOpacity = 1

        if(typeof style.fillOpacity == 'undefined' || style.fillOpacity > 0) style['fillOpacity']   = fillOpacity
        if(typeof style.strokeOpacity == 'undefined' || style.strokeOpacity > 0) style['strokeOpacity'] = strokeOpacity
      }

      if(!this.$store.getters.areMapToolsActive){
        //AS--> S'il y a toutes les infos pour définir une parcelle
        if(self.isClickable && self.$store.state.ui.sourcing.parcelles_clicables && self.isParcelle(feature)){
          style['clickable'] = !self.$store.state.ui.map_get_coords;

        }
      }else{
        style['clickable'] = false;
      }

      if(this.isParcelle()){
        if(feature.getProperty('current_status') && this.statuses_parcels.length){
          let status = this.statuses_parcels.find(s => s.slug == feature.getProperty('current_status'))
          if(status){
            style.fillColor   = status.color
            style.fillColor     = status.color
            style.strokeColor   = status.color
            style.fillOpacity   = 0.5
            style.strokeOpacity = 0.5
          }
        }
        
        // si on est en mode 'parcelles' ou 'parcelles_liste' et que la feature correspond à la parcelle en cours d'affichage, on la masque
        if(['parcelles', 'parcelles_liste'].includes(this.name) && this.$store.state.parcelle.parcelle_on_id == this.getParcelleId(feature)){
          //AS--> Passe la feature en style over
          // style = this.getOverStyle(style)
          style.strokeWeight = 0
          style.fillOpacity = 0
        }
        
        //AS--> Si on est en mode 'parcelle', on force le style pour ne pas avoir de fill et avoir un stroke qui correspond à la couleur du status de la parcelle
        if(this.name == 'parcelle'){
          // style = this.getOverStyle(style)
          style.fillColor         = "#FFC700"
          style.strokeColor       = "#FF7A00"
          style.fillOpacity       = 0.5
          style.strokeOpacity     = 0.8
          style.strokeWeight      = 3

          if(this.geoJson?.general_info?.Status){
            let status        = this.statuses_parcels.find(s => s.slug == this.geoJson.general_info.Status)
            style.fillColor   = status.color
            style.strokeColor = Colors.methods.assombrirCouleur(status.color, 30)
          }
          
        }

      }

      return style
    },

  }

})