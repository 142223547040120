import * as turf from '@turf/turf';
import AnnotationMesures from './_mesures.vue';
import AnnotationForme from './_forme.vue';

export default {
  name: 'AnnotationPolygon',
  props: {
    element: {
      type: Object,
      required: true
    }
  },
  computed: {
    perimeter() {
      if (!this.element.paths || this.element.paths.length < 2) return 0;
      const coordinates = this.element.paths.map(point => [point.lng, point.lat]);
      coordinates.push(coordinates[0]); // Fermer le polygone
      const polygon = turf.polygon([coordinates]);
      return turf.length(polygon, { units: 'kilometers' });
    },
    area() {
      if (!this.element.paths || this.element.paths.length < 3) return 0;
      const coordinates = this.element.paths.map(point => [point.lng, point.lat]);
      coordinates.push(coordinates[0]); // Fermer le polygone
      const polygon = turf.polygon([coordinates]);
      return turf.area(polygon) / 10000; // Conversion de m² en ha
    }
  },
  methods: {
    formatCoordinates(coords) {
      if (!coords || typeof coords.lat === 'undefined' || typeof coords.lng === 'undefined') {
        return '-';
      }
      return `${coords.lat.toFixed(6)}, ${coords.lng.toFixed(6)}`;
    },
    updateElement() {
      this.$store.dispatch('updateAnnotationsElement', {
        id: this.element.id,
        fillColor: this.element.fillColor,
        fillOpacity: this.element.fillOpacity,
        strokeColor: this.element.strokeColor,
        strokeOpacity: this.element.strokeOpacity,
        strokeWeight: this.element.strokeWeight
      })
    }
  },
  components: {
    AnnotationMesures,
    AnnotationForme
  }
} 