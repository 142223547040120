import _ from 'lodash'
import Loading from '../common/ui/loading_inex.vue'
import Strings from '../common/mixins/Strings'
import Numbers from '../common/mixins/Numbers'
import StatusesSelector from '../common/ui/statuses_selector.vue'

export default {
    name: 'Parcelles',
    mixins: [Strings, Numbers],
    data() {
        return {

        }
    },
    methods: {
        getContent(parcelle, field){
            let value = _.get(parcelle, field.code)

            if(field.type == 'number' && value) value = this.progressiveDecimalsCeil(value)
            if(field.type == 'siren' && value) value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
            if(field.type == 'comment' && value) value = value.replace(/\n/g, '<br>')
            if(field.limit) value = this.formatText(value, field.limit)
            return value
        },
        showParcelle(parcelle){
            let identifiant = parcelle.idu
            this.$store.dispatch('setParcelleOnId', identifiant);
        },
        formatText(text, limit){
            if(!text) return null
            text = this.onlyBr(text)
            return this.smallFull(text, limit)
        },
        getStyle(column){
            let styles = {
                width: column.width ? column.width + 'px' : 'auto',
                textAlign: column.align ? column.align : 'left'
            }
            return styles
        },
        getSubHeaderStyle(column){
            let styles = {}
            if(column.align == 'center'){
                styles.justifyContent = 'center'
            }else if(column.align == 'right'){
                styles.justifyContent = 'flex-end'
            }else {
                styles.justifyContent = 'flex-start'
            }
            return styles
        }
    },
    computed: {
        columns(){
            return this.$store.state.ui.sourcing?.parcelles_liste_params ? JSON.parse(this.$store.state.ui.sourcing.parcelles_liste_params) : []
        },
        getParcelles(){
            let self = this
            let parcelles = this.$store.state.parcelle.parcelles_list ?? this.$store.state.parcelle.parcelles_geojson
            if(!parcelles) return []

            let parcellesGeojson = _.cloneDeep(parcelles)
            
            // Retire les parcelles qui sont des LineString ou MultiLineString, indépendamment de la casse
            parcellesGeojson.features = parcellesGeojson.features.filter(parcelle => 
                !['linestring', 'multilinestring'].includes(parcelle.geometry.type.toLowerCase())
            )

            parcelles = parcellesGeojson.features.map(parcelle => {
                let properties = parcelle.properties

                self.columns.forEach(column => {
                    properties[column.code] = self.getContent(properties, column)
                })  

                return properties
            })

            return parcelles
        },
        getParcellesOrdered(){
            let parcelles = this.getParcelles
            let order = this.$store.state.parcelle.parcelles_list_order
            let direction = this.$store.state.parcelle.parcelles_list_order_direction

            // D'abord on classe les parcelles par ordre de propriétaire
            parcelles = _.sortBy(parcelles, 'proprietaire')

            // Classement numérique
            if(this.columns.find(column => column.code == order)?.type == 'number'){
                parcelles.sort((a, b) => {
                    if(direction == 'asc'){
                        return (a[order]) - (b[order])
                    }else{
                        return (b[order]) - (a[order])
                    }
                })
 
            // Classement alphabétique
            }else{
                parcelles = _.sortBy(parcelles, order)
                if(direction == 'desc') parcelles.reverse()
            }
            
            // Déplacer la parcelle sélectionnée en première position
            const selectedParcelleId = this.$store.state.parcelle.parcelle_on_id
            if (selectedParcelleId) {
                const selectedIndex = parcelles.findIndex(p => p.idu === selectedParcelleId)
                if (selectedIndex > -1) {
                    const selectedParcelle = parcelles.splice(selectedIndex, 1)[0]
                    parcelles.unshift(selectedParcelle)
                }
            }
            return parcelles
        }
    },
    components: {
        Loading,
        StatusesSelector
    }
}