export default {
	methods: {
		getMarkerIconFromSvg(svg_content, fillColor, strokeColor){

			//Remplace toutes les déclarations fill="..." par fillColor sauf fill="none"
			if(fillColor){
				svg_content = svg_content.replace(/fill="(?!none")[^"]*"/g, `fill="${fillColor}"`)
			}

			//Remplace toutes les déclarations stroke="..." par strokeColor
			if(strokeColor){
				svg_content = svg_content.replace(/stroke="[^"]*"/g, `stroke="${strokeColor}"`)
			}

			let svg_content_encoded     = encodeURIComponent(svg_content)
			let svg_content_encoded_url = 'data:image/svg+xml;charset=UTF-8,' + svg_content_encoded
			return svg_content_encoded_url
		},
	}
}
