import Card from '../../common/ui/card.vue'
import Input from '../../common/forms/Input.vue'   
import Select from '../../common/forms/Select.vue'
import ContentEditable from '../../common/forms/ContentEditable.vue'
import Phone from '../../common/forms/Phone.vue'
import LoadingBtn from '../../common/ui/loading_btn.vue'
import _ from 'lodash'
import * as turf from '@turf/turf'
import axios from 'axios'
import { vueRouterInstrumentation } from '@sentry/vue'
import Validations from '../../common/mixins/Validations.js'

export default {
	name: 'AddCompagny',
	mixins: [Validations],
    data(){
        return {
            name: '',
            address: {},
            address_formatted: '',
            phone: '',
            email: '',
            famille: '',
            masque: false
        }
    },
    watch: {
        getCoordonnees: {
            handler: _.debounce(function() {
                let self = this
                this.address = {}
                axios.get(`/plugin_projet/plugin_projet_iannuaire/get_address_from_coordinates/${this.getCoordonnees[1]}/${this.getCoordonnees[0]}`)
                    .then((response) => {
                        if (response.data.results && response.data.results[0]) {
                            this.address_formatted = response.data.results[0].formatted_address.replace(/, /, "\n")
                            this.address = {
                                address : '',
                                zip_code: '',
                                city   : '',
                                country: ''
                            }
                            response.data.results[0].address_components.forEach(function(item){
                                if(item.types.includes('street_number')){
                                    self.address.address = [item.long_name, self.address.address].join(' ').trim()
                                }
                                else if(item.types.includes('route')){
                                    self.address.address = [self.address.address, item.long_name].join(' ').trim()
                                }
                                else if(item.types.includes('postal_code')){
                                    self.address.zip_code = String(item.long_name)
                                }
                                else if(item.types.includes('locality')){
                                    self.address.city = item.long_name
                                }
                                else if(item.types.includes('country')){
                                    self.address.country = item.short_name
                                }
                            })
                        }
                    })
                    .catch((error) => {
                        console.error("Erreur de géocodage:", error)
                    })
            }, 500),
            deep: true
        }
    },
    created(){
        // Affecte la famille activée par défaut dans la config du sourcing
        if(this.$store.state.ui.sourcing.ajout_entreprises_fo_famille_defaut){
            this.famille = this.$store.state.ui.sourcing.ajout_entreprises_fo_famille_defaut
        }
    },
    methods: {
        close(){
            this.$store.dispatch('toggle_full_screen')
        },
        save(){
            this.validate_resetErrors()
            this.validateRequired('name', this.name, 'add_compagny_name_required')
            this.validateRequired('famille', this.famille, 'add_compagny_family_required')
            this.validateEmail('email', this.email, false)

            //AS--> Si il n'y a pas d'erreurs, on sauvegarde et on ferme la modal
            if(Object.keys(this.errors).length == 0){
                let data = {
                    name: this.name,
                    phone: this.phone,
                    email: this.email && this.email.length ? this.email : null,
                    families: [String(this.famille)],
                    latitude: this.getCoordonnees[1],
                    longitude: this.getCoordonnees[0],
                    ...this.address
                }
                
                // Envoie les données à l'api
                axios.post('api=inex/companies', data).then(response => {

                    console.log(_.cloneDeep(response))
                    
                    //  Convertion des données en JSON
                    if(response.data.content && typeof response.data.content == 'string'){
                        response.data = JSON.parse(response.data.content)
                    }
                    
                    console.log(_.cloneDeep(response))

                    // Ajoute le nouveau marker à la liste des markers sur la carte
                    let newMarker = {
                        _id: response.data.identifiant,
                        geometry: {
                            coordinates: [response.data.longitude, response.data.latitude]
                        },
                        f: data.families,
                        n: 1,
                        added: 1,
                        manuel: true
                    }
                    let currentMarkers = this.$store.state.entreprises.markers
                    currentMarkers.push(newMarker)
                    this.$store.commit('SET_MARKERS', currentMarkers)

                    // Ajoute l'entreprise dans le listing
                    let currentRaw = this.$store.state.entreprises.raw
                    currentRaw.push({
                        id: newMarker._id,
                        identifiant: newMarker._id,
                        nom: this.name,
                        // a: this.address,
                        tel: this.phone,
                        // e: this.email,
                        familles_ids: data.families
                    })
                    this.$store.commit('SET_ENTREPRISES', currentRaw)
                    
                }).catch(error => {
                    console.log(error)
                })

                this.close()
            }
        }
    },
    computed: {
        listFamilles(){
            let familles = this.$store.state.ui.sourcing.familles
            let listFamilles = []
            familles.forEach(famille => {
                if(famille.parent_id != 0){
                    listFamilles.push({label: famille.titre, value: famille.id})
                }
            })
            return listFamilles
        },
        getCoordonnees(){

            let coordonnees = _.cloneDeep(this.$store.getters.getAddCompagnyMarkerPosition.polygon.coordinates)

            //Calcule la position du centre de la zone en prenant le milieu de la position horizontale et la position à 40% du bas de la position verticale
            let position = [
                (coordonnees[0][0][0] + coordonnees[0][2][0]) / 2,
                coordonnees[0][2][1] + (coordonnees[0][0][1] - coordonnees[0][2][1]) * 0.4
            ]

            return position
        }
    },
	components: {
		Card,
        Input,
        Select,
        ContentEditable,
        Phone,
        LoadingBtn
	}
}