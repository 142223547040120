import * as turf from '@turf/turf';
import AnnotationMesures from './_mesures.vue';
import AnnotationForme from './_forme.vue';

export default {
  name: 'AnnotationCircle',
  data() {
    return {
      fillColor    : this.element.fillColor,
      fillOpacity  : this.element.fillOpacity,
      strokeColor  : this.element.strokeColor,
      strokeOpacity: this.element.strokeOpacity,
      strokeWeight : this.element.strokeWeight
    }
  },
  props: {
    element: {
      type: Object,
      required: true
    }
  },
  computed: {
    perimeter() {
      if (!this.element.radius || !this.element.center) return 0;
      const circle = turf.circle([this.element.center.lng, this.element.center.lat], this.element.radius / 1000);
      return turf.length(circle, { units: 'kilometers' });
    },
    area() {
      if (!this.element.radius || !this.element.center) return 0;
      const circle = turf.circle([this.element.center.lng, this.element.center.lat], this.element.radius / 1000);
      return turf.area(circle) / 10000; // Conversion de m² en ha
    },
    radius() {
      if (!this.element.radius) return 0;
      return this.element.radius / 1000; // Conversion en km
    }
  },
  methods: {
    formatCoordinates(coords) {
      return `${coords.lat.toFixed(6)}, ${coords.lng.toFixed(6)}`
    },
    formatDistance(meters) {
      if (meters >= 1000) {
        return `${(meters/1000).toFixed(2)} km`
      }
      return `${meters.toFixed(0)} m`
    },
    updateElement() {
      this.$store.dispatch('updateAnnotationsElement', {
        id: this.element.id,
        fillColor: this.fillColor,
        fillOpacity: this.fillOpacity,
        strokeColor: this.strokeColor,
        strokeOpacity: this.strokeOpacity,
        strokeWeight: this.strokeWeight
      })
    }
  },
  components: {
    AnnotationMesures,
    AnnotationForme
  }
} 