import get from "lodash/get";

export default {
    name: 'FieldBase',
    props: {
        field: String,
        label: String,
        label_position: {
            type: String,
            default: 'inner' // top ou inner
        },
        errors: Object,
        errors_position: {
            type: String,
            default: 'bottom' // bottom ou top
        }
    },
    computed: {
        getErrors(){
            let errors = get(this.errors, this.field)
            if(typeof errors == 'string' || typeof errors == 'object') errors = [this.i18n(errors)]
            return errors;
        }
    }
}