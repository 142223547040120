import kebabCase from "lodash/kebabCase";
import get from "lodash/get";
import FieldBase from './v3/_fieldBase.vue'

import 'vue-tel-input/dist/vue-tel-input.css';

export default {
  name: "Phone",
  data() {
    return {
      phone: this.value,
      focused: false,
      validation: {}
    };
  },
  props: {
    value: String,
    errors: Object,
    errors_position: {
      type: String,
      default: 'bottom'
    },
    field: String,
    label: String,
    label_position: {
      type: String,
      default: 'inner'
    },
    haut: {
      type: Boolean,
      default: true
    },
    no_mini_label: {
      type: Boolean,
      default: false
    },
  },
  created() {},
  mounted() {},
  methods: {
    _kebabCase(field) {
      return kebabCase(field);
    },
    empty(){
      this.$emit('input', '')
      if(this.viderAction) this.viderAction()
    },
    phoneValidation(validation){
      validation.valid ??= 'empty'
      validation.field = this.field
      this.validation = validation
      this.$emit('validation', validation)
    },
    change(value, tel_object){
      this.$emit('input', tel_object.number)
    }
  },
  computed: {
    getErrors() {
      let errors = get(this.errors, this.field)
      if(typeof errors == 'string') errors = [this.$t(errors)]
      return errors;
    },
    getInputClasses() {
      return ['input-' + kebabCase(this.field), { errors: this.getErrors, has_data: this.value, has_data_or_focused: this.value || this.focused, haut: this.haut, no_mini_label: this.no_mini_label}]
    }
  },
  components: {
    FieldBase
  },
};