import { MapElementFactory } from 'vue2-google-maps'

export default MapElementFactory({
	name: 'gmapText',
	
	mappedProps: {
		position: {
			type: Object,
			twoWay: true,
			required: true
		},
		text: {
			type: String,
			twoWay: true
		},
		options: {
			type: Object,
			twoWay: false
		}
	},

	props: {
		defaultOptions: {
			type: Object,
			default: () => ({
				fontColor: 'black',
				fontSize: '14px',
				fontWeight: 'normal',
				fontStyle: 'normal',
				draggable: false,
				clickable: true
			})
		}
	},

	events: ['click', 'dblclick', 'mousedown', 'mousemove', 'mouseout', 'mouseover', 'mouseup', 'rightclick', 'dragend'],

	beforeCreate(options) {
		options.$svm = this;
		if (!this.$options.propsData.ref && this.$attrs.ref) {
			this.$options.propsData.ref = this.$attrs.ref;
		}
	},

	afterCreate(inst) {
		const ref = this.$options.propsData.ref;
		if (ref) {
			inst.$ref = ref;
			inst.$svm = this;
		}
		this.$textObject = inst;
	},

	ctr() {
		class TextOverlay extends google.maps.OverlayView {
			constructor(options) {
				super();
				this.position = options.position;
				this.text = options.text || '';
				this.options = { ...this.defaultOptions, ...options.options };
				this.div = null;
				this.dragging = false;
				this.initialized = false;
				this.$svm = options.$svm;
				this.dragStartPosition = null;
				this.hasMoved = false;
				this.initialPosition = null;

				if (options.map) {
					this.setMap(options.map);
				}
			}

			// Méthodes pour les options
			setOptions(options) {
				this.options = { ...this.options, ...options };
				if (this.div) {
					const styles = this.getStyles(options)
					Object.assign(this.div.style, styles);
				}
			}

			getStyles(options) {
				let _options = options || this.options
				let styles = {
					position: 'absolute',
					color: _options.fontColor || 'black',
					fontSize: _options.fontSize || '14px',
					fontWeight: _options.fontWeight || 'normal',
					fontStyle: _options.fontStyle || 'normal',
					cursor: _options.clickable === false ? 'default' : 'pointer',
					transform: 'translate(-50%, -100%)',
					textAlign: 'left',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					userSelect: 'none',
					zIndex: '9999',
					pointerEvents: _options.clickable === false ? 'none' : 'all',
					maxWidth: '240px',
				}

				if(_options.strokeOpacity) {
					// Transforme opacity qui est un nombre entre 0 et 1 en une valeur hexadécimale entre 00 et FF
					const opacity = Math.round(_options.strokeOpacity * 255).toString(16).padStart(2, '0')
					styles.border = `${_options.strokeWeight}px solid ${_options.strokeColor}${opacity}`
				}else{
					styles.border = 'none'
				}	
				if(_options.fillOpacity) {
					// Transforme opacity qui est un nombre entre 0 et 1 en une valeur hexadécimale entre 00 et FF
					const opacity = Math.round(_options.fillOpacity * 255).toString(16).padStart(2, '0')
					styles.backgroundColor = `${_options.fillColor}${opacity}`
				}else{
					styles.backgroundColor = 'transparent'
				}
				if(_options.strokeWeight || _options.fillOpacity) {
					styles.padding = '4px 8px'
				}

				return styles
			}

			// Méthodes pour la position
			getPosition() {
				return this.position;
			}

			setPosition(position) {
				this.position = position;
				this.draw();
				google.maps.event.trigger(this, 'position_changed');
			}

			// Méthodes pour le texte
			getText() {
				return this.text;
			}

			setText(text) {
				this.text = text;
				if (this.div) {
					this.div.innerHTML = text ? text.replace(/\n/g, '<br>') : '';
				}
				google.maps.event.trigger(this, 'text_changed');
			}

			// Les autres méthodes
			createDiv() {
				try {
					const div = document.createElement('div');

					let styles = this.getStyles()

					Object.assign(div.style, styles);
					div.innerHTML = this.text ? this.text.replace(/\n/g, '<br>') : '';
					
					return div;
				} catch (error) {
					console.error('createDiv - Erreur:', error);
					return null;
				}
			}

			onAdd() {
				try {
					if (!this.div) {
						const div = this.createDiv();
						
						if (!div) {
							console.error('onAdd - Impossible de créer l\'élément div');
							return;
						}
						
						this.div = div;
					}

					const targetPane = this.getPanes().overlayImage; // mapPane -> overlayLayer -> overlayShadow -> overlayImage -> floatPane
					if (this.div.parentNode !== targetPane) {
						targetPane.appendChild(this.div);
					}

					if (!this.initialized && google.maps.event) {
						const that = this;

						// Gestion des événements standards
						['dblclick', 'rightclick', 'mouseout', 'mouseover'].forEach(eventName => {
							google.maps.event.addDomListener(this.div, eventName, function(e) {
								if (!that.dragging && that.options.clickable !== false) {
									e.stopPropagation();
									google.maps.event.trigger(that, eventName);
								}
							});
						});

						// Gestion du mousedown et du click
						google.maps.event.addDomListener(this.div, 'mousedown', function(e) {
							if (that.options.clickable === false) return;

							that.dragStartPosition = { x: e.clientX, y: e.clientY };
							that.hasMoved = false;
							google.maps.event.trigger(that, 'mousedown');

							if (!that.options.draggable) return;
							
							e.stopPropagation();
							e.preventDefault();
							that.dragging = true;
							that.getMap().set('draggable', false);
							
							// Sauvegarder la position initiale
							that.initialPosition = {
								lat: that.position.lat,
								lng: that.position.lng
							};
							
							const origin = {x: e.clientX, y: e.clientY};
							
							const moveHandler = function(e) {
								if (that.dragging) {
									const dx = e.clientX - origin.x;
									const dy = e.clientY - origin.y;

									// Vérifier si le mouvement est significatif (1 pixel suffit)
									if (Math.abs(dx) > 0.01 || Math.abs(dy) > 0.01) {
										that.hasMoved = true;
									}

									const projection = that.getProjection();
									const position = projection.fromLatLngToDivPixel(new google.maps.LatLng(
										that.position.lat,
										that.position.lng
									));
									
									position.x += dx;
									position.y += dy;
									
									const newPosition = projection.fromDivPixelToLatLng(position);
									that.setPosition({
										lat: newPosition.lat(),
										lng: newPosition.lng()
									});
									
									origin.x = e.clientX;
									origin.y = e.clientY;

									google.maps.event.trigger(that, 'mousemove');
								}
							};
							
							const upHandler = function(e) {
								if (that.dragging) {
									that.dragging = false;
									that.getMap().set('draggable', true);
									google.maps.event.trigger(that, 'mouseup');

									// Déclencher dragend seulement si on a effectivement bougé
									if (that.hasMoved && that.initialPosition) {
										const event = {
											latLng: that.position,
											oldLatLng: that.initialPosition
										};
										google.maps.event.trigger(that, 'dragend', event);
									}
								}

								// Déclencher le click seulement si on n'a pas bougé du tout
								if (!that.hasMoved) {
									google.maps.event.trigger(that, 'click');
								}
								
								that.dragStartPosition = null;
								that.hasMoved = false;
								that.initialPosition = null;
								
								google.maps.event.clearListeners(document, 'mousemove');
								google.maps.event.clearListeners(document, 'mouseup');
							};
							
							google.maps.event.addDomListener(document, 'mousemove', moveHandler);
							google.maps.event.addDomListener(document, 'mouseup', upHandler);
						});
						
						this.initialized = true;
					}

					this.draw();
				} catch (error) {
					console.error('onAdd - Erreur détaillée:', error);
				}
			}

			draw() {
				if (!this.div || !this.position) {
					return;
				}
				
				const overlayProjection = this.getProjection();
				if (!overlayProjection) {
					return;
				}
				
				const pos = overlayProjection.fromLatLngToDivPixel(this.position);
				if (!pos) {
					return;
				}

				this.div.style.left = pos.x + 'px';
				this.div.style.top = pos.y + 'px';
			}

			onRemove() {
				if (this.div) {
					if (this.div.parentNode) {
						this.div.parentNode.removeChild(this.div);
					}
					this.div = null;
					this.initialized = false;
				}
			}
		}

		return TextOverlay;
	}
});

