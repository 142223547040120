
import MenuColonneVerte_monentreprise_header from './menus/menucolonneverte_monentreprise_header.vue'
import MenuColonneVerte_parametres_header from './menus/menucolonneverte_parametres_header.vue'
import MenuColonneVerte_acteurs_header from './menus/menucolonneverte_acteurs_header.vue'
import MenuColonneVerte_sites_header from './menus/menucolonneverte_acteurs_header.vue'
import MenuColonneVerte_dechets_header from './menus/menucolonneverte_dechets_header.vue'
import MenuColonneVerte_variables_header from './menus/menucolonneverte_variables_header.vue'
import MenuColonneVerte_caracteristiques_header from './menus/menucolonneverte_caracteristiques_header.vue'
import MenuColonneVerte_secteurs_header from './menus/menucolonneverte_secteurs_header.vue'
import MenuColonneVerte_contacts_header from './menus/menucolonneverte_contacts_header.vue'
import MenuColonneVerte_allfavoris_header from './menus/menucolonneverte_allfavoris_header.vue'
import MenuColonneVerte_parcelles_header from './menus/menucolonneverte_parcelles_header.vue'
import MenuColonneVerte_suivi_header from './menus/menucolonneverte_suivi_header.vue'
import MenuColonneVerte_ui_header from './menus/menucolonneverte_ui_header.vue'
import cloneDeep from 'lodash/cloneDeep'

import FiltresMenus from './filtres/menus.vue'

export default {
	methods: {
		switch_account(event){
			if (this.$store.state.ui.content == 'account' || this.$store.state.ui.content == 'download') {
				this.$store.commit('UI_SET_SHOW_FILTERS', {filters: false});
				this.$store.commit('UI_SET_CONTENT', {content: 'result'});
			} else {
				this.$store.commit('UI_SET_SHOW_FILTERS', {filters: false});
				this.$store.commit('UI_SET_CONTENT', {content: 'account'});
			}
		},
		open_results(filter_index){
			let show_filters = filter_index == this.$store.state.ui.show_filter_index ? !this.$store.state.ui.show_filters : true
			this.$store.commit('UI_SET_SHOW_FILTERS', {filters: show_filters});
			this.$store.commit('UI_SET_FILTER_INDEX', {filter_index: filter_index});
			if (this.$store.state.ui.content != 'result' && this.$store.state.ui.content != 'graph') {
				this.$store.commit('UI_SET_CONTENT', {content: 'result'});
			}
		},
		zoomAuto(){
			this.$store.commit('UI_UPDATE_ZOOMAUTO');
		},
		debug(){
			let debug = {}
			debug.company      = this.$store.state.company
			debug.entreprises  = this.$store.state.entreprises
			debug.filters      = this.$store.state.filters
			debug.graph        = this.$store.state.graph
			debug.nomenclature = this.$store.state.nomenclature
			debug.parcelle     = this.$store.state.parcelle
			debug.searches     = this.$store.state.searches
			debug.ui           = this.$store.state.ui
			debug.user         = this.$store.state.user
			debug.annotations  = this.$store.state.annotations
			console.log(debug)
		}
	},
	computed: {
		canShowStores(){
			let urlParams = new URLSearchParams(window.location.search)
			let showstores = urlParams.has('showstores')
			return this.$store.state.ui.env == 'DEV' || showstores
		}
	},
	components: {
		MenuColonneVerte_monentreprise_header,
		MenuColonneVerte_parametres_header,
		MenuColonneVerte_acteurs_header,
		MenuColonneVerte_sites_header,
		MenuColonneVerte_dechets_header,
		MenuColonneVerte_variables_header,
		MenuColonneVerte_caracteristiques_header,
		MenuColonneVerte_secteurs_header,
		MenuColonneVerte_contacts_header,
		MenuColonneVerte_allfavoris_header,
		MenuColonneVerte_parcelles_header,
		MenuColonneVerte_suivi_header,
		MenuColonneVerte_ui_header,

		FiltresMenus,
	},
}