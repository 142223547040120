import kebabCase from "lodash/kebabCase";
import get from "lodash/get";
import FieldBase from './v3/_fieldBase.vue'

export default {
  name: "Input",
  emits: ['input'],
  data() {
    return {};
  },
  props: {
    value: String,
    datas: Object,
    errors: Object,
    type: {
      type: String,
      default: "text",
    },
    maxlength: {
      type: Number,
      default: -1,
    },
    step: {
      type: Number,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    field: String,
    label: String,
    label_position: {
      type: String,
      default: 'inner' // top ou inner
    },
    errors_position: {
      type: String,
      default: 'bottom' // bottom ou top
    },
    picto: String,
    pictoRight: String,
    viewBox: {
      type: String,
      default: '0 0 24 24'
    },
    pictoColor: {
      type: String,
      default: '' //AS--> Green by default. Others : black
    },
    vider: {
      type: Boolean,
      default: false //AS--> True to show a cross at the right
    },
    viderAction: {
      type: Function
    },
    autoFocus: {
      type: Boolean,
      default: false
    },
    haut: {
      type: Boolean,
      default: false 
    },
    no_mini_label: {
      type: Boolean,
      default: false 
    },
  },
  created() {},
  mounted() {
    if(this.autoFocus){
      // this.$refs.thisinput.focus()
    }
  },
  methods: {
    _kebabCase(field) {
      return kebabCase(field);
    },
    empty(){
      this.$emit('input', '')
      if(this.viderAction) this.viderAction()
    }
  },
  computed: {
    getErrors() {
      let errors = get(this.errors, this.field)
      if(typeof errors == 'string' || typeof errors == 'object') errors = [this.i18n(errors)]
      return errors;
    },
    getInputClasses() {
      return ['input-' + kebabCase(this.field), 'input-' + kebabCase(this.type), { haut: this.haut, no_mini_label: this.no_mini_label, errors: this.getErrors, picto: this.picto, picto_right: this.pictoRight, vider: this.vider, has_data: this.value}]
    }
  },
  components: {
    FieldBase
  },
  directives: {
    noAutofocus: {
      inserted(el) {
        el.setAttribute('tabindex', '-1'); // Rend l'élément focusable sans le focaliser automatiquement
      }
    }
  }
};
