import MenuSousMenu from './menu_sous-menu.vue'
import axios from 'axios'

export default {
	name: 'Statuses_Selector',
    emits: ['change'],
	data() {
		return {
			
		}
	},
	props: {
        element: {
            type: Object,
            required: true
        },
        target: {
            type: String,
            required: false,
            default: 'companies'
        },
        field: {
            type: String,
            required: false,
            default: 'current_status'
        },
        tooltip_length: {
            type: Number,
            required: false,
            default: 15
        }
    },
	created(){
		
	},
	methods: {
        changeStatus(status){
            this.$emit('change', status.slug)
            if(this.target == 'companies'){
                this.$store.dispatch('updateFieldCompany', {identifiant: this.element.identifiant, field: this.field, value: status.slug, format: 'status'})
            }else if(this.target == 'parcels'){
                this.$store.dispatch('updateFieldParcelle', {identifiant: this.element.identifiant, field: this.field, value: status.slug, format: 'status'})
            }
        }
	},
    computed:{
        menu_statuses(){
            let menu = {
                menu: this.status,
                sousmenu: {
                    class: 'no-bold menu_statuses_list',
                    list: []
                }
            }

            if(this.$store.getters.getStatuses(this.target)?.length){
                this.$store.getters.getStatuses(this.target).forEach(status => {
                    menu.sousmenu.list.push({
                        label    : status.name,
                        slug     : status.slug,
                        bg_color : status.color,
                        action   : () => this.changeStatus(status)
                    })
                })
            }

            menu.sousmenu.list.push({
                label    : this.$t('statuses_no_status'),
                slug     : null,
                bg_color : this.default_color,
                action   : () => this.changeStatus({slug: null})
            })

            //-> configuration
            if(this.$store.getters.canManageStatuses){
                menu.sousmenu.list.push({
                    class: 'separator'
                })
                menu.sousmenu.list.push({
                    class: 'action-green',
                    label: this.$t('statuses_configuration_btn'),
                    action: () => {
                        this.$store.dispatch('open_modal', 'edition_statuses')
                        this.$store.commit('UI_SET_STATUS_TARGET_EDITION', this.target)
                    },
                    picto_post: 'Plus'
                })
            }

            return menu
        },
        status(){
            let status = this.$store.getters.getStatuses(this.target).filter(status => status.slug === this.element[this.field])[0]
            if(status){
                return {
                    picto: false,
                    label: status.name,
                    arrow: true,
                    bg_color: status.color
                }
            }else{
                return {
                    picto: false,
                    label: this.$t('statuses_no_status'),
                    arrow: true,
                    bg_color: this.default_color
                }
            }
        },
        default_color(){
            return this.target == 'parcels' ? '#FEFAE9' : "#E9F0FD"
        }
    },
	components:{
		MenuSousMenu
	}
}