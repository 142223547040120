import SvgMixin from '../../../common/mixins/Svg'

export default {
    name: 'AnnotationFormIcon',
    mixins: [SvgMixin],
    props: {
        element: {
            type: Object,
            required: true
        }
    },
    computed: {
        pictos() {
            return this.$store.state.annotations.pictos || {};
        }
    },
    methods: {
        updateIcon(iconKey) {
            this.$store.dispatch('updateAnnotationsElement', {
                id: this.element.id,
                icon: iconKey
            });
        }
    }
} 