import AnnotationFormTexte from './_texte.vue'
import AnnotationFormIcon from './_icon.vue'
import AnnotationFormForme from './_forme.vue'

export default {
  name: 'AnnotationMarker',
  props: {
    element: {
      type: Object,
      required: true
    }
  },
  methods: {
    formatCoordinates(coords) {
      if (!coords || typeof coords.lat === 'undefined' || typeof coords.lng === 'undefined') {
        return '-';
      }
      const lat = parseFloat(coords.lat);
      const lng = parseFloat(coords.lng);
      if (isNaN(lat) || isNaN(lng)) {
        return '-';
      }
      return `${lat.toFixed(6)}, ${lng.toFixed(6)}`;
    },
    updateElement() {
      this.$store.dispatch('updateAnnotationsElement', {
        id: this.element.id,
        label: this.element.label,
        icon: this.element.icon
      })
    }
  },
  computed: {
    canModifyStroke() {
      return this.$store.state.annotations.pictos[this.element.icon].canModifyStrokeColor
    },
    canModifyFill() {
      return this.$store.state.annotations.pictos[this.element.icon].canModifyFillColor
    }
  },
  components: {
    AnnotationFormTexte,
    AnnotationFormIcon,
    AnnotationFormForme
  }
} 