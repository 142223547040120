import menu_sous_menu from '@/common/ui/menu_sous-menu.vue'
import InputForm from '@/common/forms/Input.vue'
import TextareaForm from '@/common/forms/ContentEditable.vue'

export default {
    name: 'AnnotationFormTexte',
    data() {
        return {
            texte: '',
            menuSize: {
                menu: {
                    label: '',
                    arrow: false
                },
                sousmenu: {
                    class: 'menu_size_list',
                    list: [
                        { label: '10px', action: () => this.updateFontSize(10) },
                        { label: '12px', action: () => this.updateFontSize(12) },
                        { label: '14px', action: () => this.updateFontSize(14) },
                        { label: '16px', action: () => this.updateFontSize(16) },
                        { label: '18px', action: () => this.updateFontSize(18) },
                        { label: '20px', action: () => this.updateFontSize(20) },
                        { label: '22px', action: () => this.updateFontSize(22) },
                        { label: '24px', action: () => this.updateFontSize(24) },
                        { label: '30px', action: () => this.updateFontSize(30) },
                        { label: '40px', action: () => this.updateFontSize(40) },
                    ]
                }
            },
            menuWeight: {
                menu: {
                    label: '',
                    arrow: false
                },
                sousmenu: {
                    class: 'menu_weight_list',
                    list: [
                        { label: this.$t('annoter_outil_fontweight_normal'), action: () => this.updateFontWeight('normal') },
                        { label: this.$t('annoter_outil_fontweight_bold'), action: () => this.updateFontWeight('bold') }
                    ]
                }
            }
        }
    },
    props: {
        element: {
            type: Object,
            required: true
        },
        format: {
            type: String,
            required: false,
            default: 'text' // text, textarea
        }
    },
    watch: {
        element: function(after, before) {
            if(after.id !== before.id) this.initialize()
        }
    },
    created() {
        this.initialize()
    },
    methods: {
        initialize(){
            this.texte = this.element.text
            this.menuSize.menu.label = this.element.fontSize + 'px'
            this.menuWeight.menu.label = this.element.fontWeight === 'bold' ? this.$t('annoter_outil_fontweight_bold') : this.$t('annoter_outil_fontweight_normal')
        },
        updateElement() {
            const updatedStyle = {
                ...(this.element.style || {}),
                fontSize: this.element.fontSize,
                fontWeight: this.element.fontWeight,
                fontColor: this.element.fontColor
            }

            this.$store.dispatch('updateAnnotationsElement', {
                id: this.element.id,
                text: this.texte,
                style: updatedStyle
            })
        },
        updateFontSize(size) {
            this.element.fontSize = size
            this.menuSize.menu.label = size + 'px'
            this.updateElement()
        },
        updateFontWeight(weight) {
            this.element.fontWeight = weight
            this.menuWeight.menu.label = weight
            this.updateElement()
        }
    },
    components: {
        menu_sous_menu,
        InputForm,
        TextareaForm
    }
}
