import kebabCase from "lodash/kebabCase";
import get from "lodash/get";
import HtmlTricks from '../mixins/HtmlTricks.js'
import FieldBase from './v3/_fieldBase.vue'

export default {
  mixins: [HtmlTricks],
  name: "ContentEditable",
  data() {
    return {
      unique_id: _.uniqueId('contenteditable_'),
      innerHTML: null,
      editable: false,
      modeValue: 'data' // data, value
    };
  },
  props: {
    field: {
      type: String,
      default: null
    },
    value: {
      type: [String, Number],
      default: null
    },
    errors: {
      type: Object,
      default: null
    },
    data: {
      type: [String, Number, Boolean, Array, Object],
      default: null
    },
    edition: {
      type: Boolean,
      default: false
    },
    always_editable: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    placeholder_fix: {
      type: Boolean,
      default: false
    },
    focusOnEdition: {
      type: Boolean,
      default: false
    },
    spanClass: {
      type: String,
      default: ''
    },
    errors_position: {
      type: String,
      default: 'bottom'
    },
    label_position: {
      type: String,
      default: 'inner'
    },
    top0: {
      type: Boolean,
      default: false
    },
    inline: {
      type: Boolean,
      default: false
    },
    activable_on_click: {
      type: Boolean,
      default: false
    }
  },
  watch:{
    edition: function(after, before){
      let self = this
      if(after == false){
        if(this.modeValue == 'data') this.$refs.champ.textContent = this.innerHTML
        this.editable = false
      }
      if(after == true){
        setTimeout(function(){  // Nécessaire pour que le composant soit ré-évalué
          self.editable = true
        }, 5)
        if(this.focusOnEdition){
          setTimeout(function(){
            self.focus_at_end_contenteditable(self.$refs.champ)
          }, 50)
        }
      }
    },
    always_editable: function(after){
      if(after) this.editable = true
    },
    value: function(after, before) {
      let _after = _.cloneDeep(after).replace(/\n/g, '').replace('<br>', '')
      this.$nextTick(() => {
        let _champ = _.cloneDeep(this.$refs.champ.textContent).replace(/\n/g, '').replace('<br>', '')
        if (_after !== _champ) {
        this.innerHTML = after
        if (typeof this.innerHTML === 'string') {
            this.innerHTML = this.innerHTML.replace(/\n/g, '<br>')
          }
        }
      })
    }
  },
  created() {
    if(this.data){
      this.modeValue = 'data'
      this.innerHTML = this.data
      if(typeof this.innerHTML == 'string') this.innerHTML = this.innerHTML.trim()
      this.$emit('input', this.innerHTML)
    }else{
      this.modeValue = 'value'
      this.innerHTML = this.value
    }
    // Remplace les retours à la ligne réels par <br>
    if(this.innerHTML && typeof this.innerHTML == 'string') this.innerHTML = this.innerHTML?.replace(/\n/g, '<br>')
    this.editable = this.always_editable ? true : _.cloneDeep(this.edition)
  },
  mounted() {
    document.querySelector("#app_content")?.addEventListener('click', this.deactivate)
  },
  beforeDestroy() {
    document.querySelector("#app_content")?.removeEventListener('click', this.deactivate)  
  },
  methods: {
    _kebabCase(field) {
      return kebabCase(field);
    },
    input(event){
      let value = event.target.innerText
      if(typeof value == 'string' && this.modeValue == 'data') value = value.trim()
      this.$emit('input', value)
    },
    placeholder_activate(){
      if(this.edition) this.focus_at_end_contenteditable(this.$refs.champ)
    },
    activate(){
      let self = this
      if(this.activable_on_click){
        if(!this.editable && !this.always_editable){
          this.editable = true
          setTimeout(function(){
            self.focus_at_end_contenteditable(self.$refs.champ)
          }, 10)
        }
      }

    },
    deactivate(event){
      if (!this.always_editable && this.activable_on_click && event.target.closest('.' + this.uniqueClass) == null) this.editable = false
    }
  },
  computed: {
    uniqueClass(){
      return 'contenteditable_' + this.unique_id
    },
    hasErrors(){
      return this.errors && this.errors[this.field] && this.errors[this.field].length > 0
    },
    getClasses(){
      let classes = [this.uniqueClass]
      if(this.always_editable) classes.push('always_editable')
      if(this.editable) classes.push('field_edition')
      if(this.hasContent) classes.push('has-content')
      if(this.placeholder_fix) classes.push('placeholder_fix')
      if(this.hasErrors) classes.push('errors')
      if(this.top0) classes.push('top0')
      if(this.inline) classes.push('inline')
      return classes
    },
    hasContent(){
      if(typeof this.value == 'number') return this.value != null
      return this.value && this.value?.trim().length > 0
    },
  },
  components: {
    FieldBase
  },
};
