import Teleport from 'vue2-teleport';

export default {
	name: 'Modale',
	emits: ['close'],
	data() {
		return {
			
		}
	},
	props: {
		closeBtn: { 		//AS--> Pour afficher ou non la croix de fermeture
			type: Boolean,
			default: true
		},
		picto: {
			default: '',
			type: [String, Boolean]
		},
		titre: {
			default: '',
			type: [String, Boolean]
		},
		w: {
			type: Number,
			default: 24
		},
		h: {
			type: Number,
			default: 24
		},
		vw: {
			type: Number,
			default: 24
		},
		vh: {
			type: Number,
			default: 24
		},
		auto: {
			type: Boolean,
			default: false
		}
	},
	created(){
		
	},
	methods: {
		execCloseModal(){
			this.$store.dispatch('close_modal')
			this.$emit('close')
		}
	},
	computed:{
	},
	components:{
		Teleport
	}
}