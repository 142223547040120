import * as turf from '@turf/turf';
import AnnotationMesures from './_mesures.vue';
import AnnotationForme from './_forme.vue';

export default {
  name: 'AnnotationRectangle',
  props: {
    element: {
      type: Object,
      required: true
    }
  },
  computed: {
    perimeter() {
      if (!this.element.bounds) return 0;
      const { north, south, east, west } = this.element.bounds;
      const coordinates = [
        [west, north],
        [east, north],
        [east, south],
        [west, south],
        [west, north]
      ];
      const polygon = turf.polygon([coordinates]);
      return turf.length(polygon, { units: 'kilometers' });
    },
    area() {
      if (!this.element.bounds) return 0;
      const { north, south, east, west } = this.element.bounds;
      const coordinates = [
        [west, north],
        [east, north],
        [east, south],
        [west, south],
        [west, north]
      ];
      const polygon = turf.polygon([coordinates]);
      return turf.area(polygon) / 10000; // Conversion de m² en ha
    }
  },
  methods: {
    updateElement() {
      this.$store.dispatch('updateAnnotationsElement', {
        id: this.element.id,
        fillColor: this.element.fillColor,
        fillOpacity: this.element.fillOpacity,
        strokeColor: this.element.strokeColor,
        strokeOpacity: this.element.strokeOpacity,
        strokeWeight: this.element.strokeWeight
      })
    }
  },
  components: {
    AnnotationMesures,
    AnnotationForme
  }
} 