import AnnotationFormTexte from './_texte.vue'
import AnnotationForme from './_forme.vue'
export default {
  name: 'AnnotationText',
  props: {
    element: {
      type: Object,
      required: true
    }
  },
  methods: {
    formatCoordinates(coords) {
      return `${coords.lat.toFixed(6)}, ${coords.lng.toFixed(6)}`
    },
    updateElement() {
      this.$store.dispatch('updateAnnotationsElement', {
        id: this.element.id,
        text: this.element.text,
        style: {
          ...this.element.style,
          fontColor: this.element.style.fontColor,
          fontSize: `${this.element.style.fontSize}px`
        }
      })
      
      const textComponent = this.$root.$children[0].$refs[this.element.id]?.[0]
      if (textComponent?.$textObject) {
        textComponent.$textObject.setOptions({
          text: this.element.text,
          fontColor: this.element.style.fontColor,
          fontSize: `${this.element.style.fontSize}px`
        })
      }
    }
  },
  components: {
    AnnotationFormTexte,
    AnnotationForme
  },
} 