import kebabCase from "lodash/kebabCase";
import get from "lodash/get";
import _ from "lodash";
import CustomInput from "./Input.vue";
import FieldBase from "./v3/_fieldBase.vue";

export default {
  name: "Select",
  emits: ['update:modelValue', 'onSelect'],
  data() {
    return {
      option: {},
      selfsearch_value: '',
      opened: false
    };
  },
  props: {
    value: {},
    options: {
      type: Array
    },
    datas: Object,
    errors: Object,
    errors_position: {
      type: String,
      default: 'bottom'
    },
    type: {
      type: String,
      default: "text",
    },
    maxlength: {
      type: Number,
      default: -1,
    },
    step: {
      type: Number,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    selfsearch: {
      type: Boolean,
      default: false,
    },
    field: String,
    label: String,
    label_position: {
      type: String,
      default: 'inner'
    },
    placeholder: String,
    picto: String,
    viewBox: {
      type: String,
      default: '0 0 24 24'
    },
    //AS--> Pour donner la possibilité de vider le champ, indiquer une valeur à vide
    empty:{
      default: 'noempty'
    },
    //AS--> Pour changer le label du vide
    empty_label:{
      type: String,
      default: ''
    },
    autoEmptyAfterSelect: {
      type: Boolean,
      default: false
    },
    haut: {
      type: Boolean,
      default: false 
    },
    no_mini_label: {
      type: Boolean,
      default: false 
    },
    w100: {
      type: Boolean,
      default: false 
    },
  },
  created() {
    let self = this
    if(this.value != null && typeof this.value != 'undefined' && (this.value.length || ['number', 'object'].includes(typeof this.value)) && this.options.length){
      this.options.forEach(function(option){
        if(_.isEqual(option.value, self.value)) self.option = option
      })
    }
  },
  mounted(){
    //AS--> Ajout un événement sur le body pour fermer la modale
    document.querySelector("#app_content")?.addEventListener('click', this.close);
  },
  beforeDestroy: function() {
    document.querySelector("#app_content")?.removeEventListener('click', this.close);
  },
  watch: {
    value: function(){
      let self = this
      if(this.value != null && typeof this.value != 'undefined' && (this.value.length || ['number', 'object'].includes(typeof this.value)) && this.options.length){
        this.options.forEach(function(option){
          if(option.value == self.value) self.option = option
        })
      }else{
        this.option = {}
        this.opened = false
      }
    }
  },
  methods: {
    _kebabCase(field) {
      return kebabCase(field);
    },
    select(option){
      if(!this.autoEmptyAfterSelect){
        this.option = option
        this.$emit('input', option.value)
        this.$emit('update:modelValue', option.value)
      }
      this.$emit('onSelect', option.value)
      this.opened = false
    },
    close(event){
      let self = this
      setTimeout(function(){
        if (event.target.closest('.input.select') == null) {
          self.opened = false
        }
      }, 10)
    },
    empty_value(){
      this.option = {}
      this.$emit('input', this.empty)
      this.$emit('update:modelValue', this.empty)
      this.$emit('onSelect', this.empty)
      this.opened = false
    },
    sort(list){
      return _.sortBy(list, 'label')
    }
  },
  computed: {
    getErrors() {
      let errors = get(this.errors, this.field)
      if(typeof errors == 'string' || typeof errors == 'object') errors = [this.i18n(errors)]
      return errors;
    },
    showSelfSearch(){
      return this.selfsearch && this.options.length > 10
    },
    getOptions(){
      //AS--> S'il n'y a pas de recherche interne, on renvoie toutes les options
      if(!this.showSelfSearch || !this.selfsearch_value) return this.options

      //AS--> Sinon on filtre les options à renvoyer
      // let regex = new RegExp(this.selfsearch_value.split('').join('.*'), 'i') // regex du style a.*g.*s.*t.* ...
      let regex = new RegExp('.*'+this.selfsearch_value+'.*', 'i') // regex 'qui contient'
      return _.filter(this.options, function(o) {
        return o.label && o.label.match(regex)
      });
    },
    getOptionLabel(){
      if(this.value == this.empty) return this.empty_label
      if(this.option.label) return this.option.label
      if(this.label) return ''
      return this.placeholder || this.$t(this.label)
    },
    hasOptionLabel(){
      if(this.value == this.empty) return false
    },
    getSelectClasses() {
      return [
        'select-' + kebabCase(this.field), 
        { 
          errors       : this.getErrors,
          picto        : this.picto,
          'opened'     : this.opened,
          has_data     : this.value && this.value != this.empty,
          with_label   : this.label,
          w100         : this.w100,
          haut         : this.haut,
          no_mini_label: this.no_mini_label,
        }
      ]
    }
  },
  components: {
    CustomInput,
    FieldBase
  },
};
