export default {
	data(){
		return {
			errors: {}
		}
	},
	methods: {
		validate_resetErrors(){
			this.errors = {}
		},
		validate_addError(field, error) {
			if(this.errors[field] == undefined) this.errors[field] = []
			this.errors[field].push(error)
		},

		validateRequired(field, value, error_msg = null) {
			if(value.length == 0) this.validate_addError(field, this.$t(error_msg ? error_msg : 'field_error_required'))
		},

		validateEmail(field, value, required = true, error_msg = null, error_msg_required = null) {
			if(required && value.length == 0) this.validate_addError(field, this.$t(error_msg_required))
			var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			if(value.length > 0 && !re.test(String(value).toLowerCase())) this.validate_addError(field, this.$t(error_msg ? error_msg : 'field_error_email'))
		}
	}
}