import ctype_print from 'locutus/php/ctype/ctype_print'
import _ from 'lodash'

export default {
	data() {
		return {
			map: null,
			drawingManager: null,
			defaultStyle: {
				strokeWeight: 3,
				strokeOpacity: 1,
				strokeColor: '#3861FB',
				fillOpacity: 0.4,
				fillColor: '#3861FB',
				draggable: true,
			},
			get_coords: false,
			infoWindowInfos: null,
			zIndexBase: 500
		}
	},
	watch: {

		"$store.state.ui.modale_gauche": function(newVal, oldVal){
			if(oldVal == 'OutilAnnoter'){
				this.$store.dispatch('setAnnotationsDrawingMode', null)
				this.$store.dispatch('toggleSelectAnnotationsElement', null)
			}
		},

		"$store.state.annotations.drawingMode": {
			handler(newVal) {
				if(newVal == 'text'){
					this.get_coords = 'text'
				}else{
					this.drawingManager.setDrawingMode(newVal)
					if(newVal == null) this.get_coords = false
				}
			},
		},

		//AS--> Surveille l'acquisition de coordonnées au clic sur la map
		"$store.state.ui.map_coords_pointed": function(after, before){
			if(this.get_coords && after){
				//AS--> Ajoute un élément de texte à la carte
				if(this.get_coords == 'text') this.addText(after)
				//AS--> Désactive le mode de dessin
				this.$store.dispatch('setAnnotationsDrawingMode', null)
			}
		},

		//AS--> Surveille l'acquisition de coordonnées au clic sur un marker
		"$store.state.ui.map_pin_pointed": function(after, before){
			if(this.get_coords && after){
				//AS--> Ajoute un élément de texte à la carte
				if(this.get_coords == 'text') this.addText(after)
				//AS--> Désactive la sélection de coordonnées
				this.$store.dispatch('setAnnotationsDrawingMode', null)
			}
		}

	},
	mounted() {

		//RZ--> La carte est créée
		this.$refs.map.$mapPromise.then(() => {

			this.map = this.$refs.map.$mapObject
			this.map.__vueContext = this;

			this.initDrawerManager(this.map)

			//----------------------------------------------------------
			//AS--> Détection de la touche supprimer
			document.addEventListener('keydown', this.handleKeyElement)


		})


	},
	beforeDestroy() {
		document.removeEventListener('keydown', this.handleKeyElement)
	},
	methods: {

		//AS--> Centralisateur des détections de touches
		handleKeyElement(event) {
			//AS--> Vérifie si l'utilisateur n'est pas en train d'éditer un champ de texte
			const activeElement = document.activeElement;
			
			const isEditing = (activeElement.tagName === 'INPUT' && activeElement.type === 'text') || 
				activeElement.tagName === 'TEXTAREA' || 
				activeElement.classList.contains('form_contenteditable');
			
			//AS--> Détection de la touche supprimer, suppression des éléments gérés par le mixin seulement si on n'est pas en train d'éditer
			if (['Delete', 'Backspace'].includes(event.key) && !isEditing) {
				this.handleDeleteElement();
			}
		},

		// Fonction utilitaire pour obtenir le point le plus haut d'un élément
		getHighestPoint(element) {
			if (!element) return null;

			try {
				switch(element.type) {
					case 'marker':
					case 'text':
						return element.position;
					case 'circle':
						if (!element.center || typeof element.center.lat !== 'number' || typeof element.center.lng !== 'number' || typeof element.radius !== 'number') {
							return null;
						}
						return {
							lat: element.center.lat + (element.radius / 111320), // 1 degré = 111.32 km
							lng: element.center.lng
						};
					case 'rectangle':
						if (!element.bounds) return null;
						
						const { north, south, east, west } = element.bounds;
						
						// Vérifie que toutes les coordonnées sont des nombres valides et dans des plages acceptables
						if (typeof north !== 'number' || typeof south !== 'number' || 
							typeof east !== 'number' || typeof west !== 'number' ||
							north < -90 || north > 90 || south < -90 || south > 90 ||
							east < -180 || east > 180 || west < -180 || west > 180 ||
							north < south) {
							return null;
						}

						const centerLng = (east + west) / 2;
						// Assure que la longitude moyenne est dans la plage -180 à 180
						const normalizedLng = ((centerLng + 180) % 360) - 180;

						return {
							lat: north,
							lng: normalizedLng
						};
					case 'polygon':
					case 'polyline':
						if (!Array.isArray(element.paths) || element.paths.length === 0) {
							return null;
						}
						let highestLat = -90;
						let avgLng = 0;
						let validPoints = 0;

						element.paths.forEach(point => {
							if (point && typeof point.lat === 'number' && typeof point.lng === 'number') {
								highestLat = Math.max(highestLat, point.lat);
								avgLng += point.lng;
								validPoints++;
							}
						});

						return validPoints > 0 ? {
							lat: highestLat,
							lng: avgLng / validPoints
						} : null;
					default:
						return null;
				}
			} catch (error) {
				console.error('Error calculating highest point:', error);
				return null;
			}
		},

		initDrawerManager(map_object) {
			// https://developers.google.com/maps/documentation/javascript/reference/drawing?hl=fr
			// https://vue-map.netlify.app/components/marker.html
			this.drawingManager = new window.google.maps.drawing.DrawingManager({
				drawingMode: null,
				drawingControl: false,
				drawingControlOptions: {
					position: window.google.maps.ControlPosition.TOP_CENTER,
					drawingModes: [
						window.google.maps.drawing.OverlayType.MARKER,
						window.google.maps.drawing.OverlayType.CIRCLE,
						window.google.maps.drawing.OverlayType.POLYGON,
						window.google.maps.drawing.OverlayType.POLYLINE,
						window.google.maps.drawing.OverlayType.RECTANGLE,
					],
				},
				markerOptions: {
					icon: this.$store.state.annotations.pictos.pin,
					label: {
						text: '',
						color: '#3C4043',
						fontSize: 12,
						fontWeight: 'normal'
					},
					draggable: true,
				},
				circleOptions: {
					...this.defaultStyle,
				},
				rectangleOptions: {
					...this.defaultStyle,
				},
				polylineOptions: {
					...this.defaultStyle,
				},
			});
			this.drawingManager.setMap(map_object);

			// Ecouteur sur la fin de la création d'un cercle
			this.drawingManager.addListener("circlecomplete", (Circle) => {
				this.$store.dispatch('setAnnotationsDrawingMode', null)
				let options = this.elementGetOptions(Circle, 'circle')
				this.$store.dispatch('addAnnotationsElement', options).then(() => {
					setTimeout(() => {
						Circle.setMap(null);
					}, 100)
				})
			});

			// Ecouteur sur la fin de la création d'un rectangle
			this.drawingManager.addListener("rectanglecomplete", (Rectangle) => {
				this.$store.dispatch('setAnnotationsDrawingMode', null)
				let options = this.elementGetOptions(Rectangle, 'rectangle')
				this.$store.dispatch('addAnnotationsElement', options).then(() => {
					setTimeout(() => {
						Rectangle.setMap(null);
					}, 100)
				})
			});

			// Ecouteur sur la fin de la création d'un marqueur
			this.drawingManager.addListener("markercomplete", (Marker) => {
				this.$store.dispatch('setAnnotationsDrawingMode', null)
				let options = this.elementGetOptions(Marker, 'marker')
				options.fontColor   = '#3C4043'
				options.fontSize    = 12
				options.fontWeight  = 'normal'
				options.text        = ''
				options.strokeColor = '#3C4043'
				options.fillColor   = '#3C4043'
				this.$store.dispatch('addAnnotationsElement', options).then(() => {
					setTimeout(() => {
						Marker.setMap(null);
					}, 100)
				})
			});

			// Ecouteur sur la fin de la création d'un polyline
			this.drawingManager.addListener("polylinecomplete", (Polyline) => {
				this.$store.dispatch('setAnnotationsDrawingMode', null)

				// Vérifie si le polyline est fermé
				const path = Polyline.getPath();
				const isClosed = path.getAt(0).equals(path.getAt(path.getLength() - 1));

				if (isClosed) {
					let options = this.elementGetOptions(Polyline, 'polygon')
					this.$store.dispatch('addAnnotationsElement', options).then(() => {
						setTimeout(() => {
							Polyline.setMap(null);
						}, 100)
					})
				} else {
					let options = this.elementGetOptions(Polyline, 'polyline')
					this.$store.dispatch('addAnnotationsElement', options).then(() => {
						setTimeout(() => {
							Polyline.setMap(null);
						}, 100)
					})
				}
			});
		},

		getElement(id) {
			return this.$refs[id]?.[0]
		},

		elementGetOptions(element, type) {
			let options = {
				type: type
			}

			if (['circle', 'rectangle', 'polygon'].includes(type)) {
				options.fillColor = element.fillColor
				options.fillOpacity = element.fillOpacity
				options.strokeColor = element.strokeColor
				options.strokeOpacity = element.strokeOpacity
				options.strokeWeight = element.strokeWeight
			}

			if (['polyline'].includes(type)) {
				options.strokeColor = element.strokeColor
				options.strokeOpacity = element.strokeOpacity
				options.strokeWeight = element.strokeWeight
			}

			if (type == 'circle') {
				options.center = {
					lat: element.getCenter().lat(),
					lng: element.getCenter().lng()
				}
				options.radius = element.getRadius()
			}

			if (type == 'rectangle') {
				const bounds = element.getBounds();
				options.bounds = {
					north: bounds.getNorthEast().lat(),
					east: bounds.getNorthEast().lng(),
					south: bounds.getSouthWest().lat(),
					west: bounds.getSouthWest().lng(),
				}
			}

			if (type == 'marker') {
				options.position = {
					lat: element.getPosition().lat(),
					lng: element.getPosition().lng()
				}
				options.icon = 'pin'
			}

			if (['polygon', 'polyline'].includes(type)) {
				const paths = element.getPath().getArray()
				options.paths = paths.map(path => ({
					lat: path.lat(),
					lng: path.lng()
				}))
			}

			return options
		},

		addText(position){
			let styles = this.defaultStyle
			styles.fillColor = '#FFFFFF'
			styles.fillOpacity = 1
			styles.strokeColor = '#888888'
			styles.strokeWeight = 1
			styles.strokeOpacity = 1

			this.$store.dispatch('addAnnotationsElement', {
				type: 'text',
				position: position,
				text: null,
				selected: true,
				fontColor: '#3C4043',
				fontSize: '12',
				fontWeight: 'normal',
				...styles,
			})
		},

		handleAnnotationClick(element) {
			const component = this.$refs[element.id]?.[0];

			if (!component) return

			let forme = component[`$${element.type}Object`]

			if (forme) {

				// toggle editable
				if (typeof forme.getEditable === 'function') {
					if (forme.getEditable()) {
						forme.setEditable(false)
					} else {
						forme.setEditable(true)
					}
				}

				// Prend la liste de tous les autres éléments mémorisés dans le store annotations et supprime leur mode editable
				this.$store.state.annotations.elements.forEach(__element => {
					if (__element.id != element.id) {
						let other_component = this.$refs[__element.id]?.[0]
						if (other_component) {
							if (typeof other_component[`$${__element.type}Object`].setEditable === 'function') other_component[`$${__element.type}Object`].setEditable(false)
						}
					}
				})

				//AS--> Toggle la sélection de l'élément dans le store
				this.$store.dispatch('toggleSelectAnnotationsElement', element.id)

			} else {
				console.error('Référence à l\'élément non trouvée.');
			}
		},

		//AS--> Cercle : déplacement du centre
		handleDragendCircle: _.debounce(function (circle) {

			//Trouve l'élément sur la carte par son id
			const element = this.getElement(circle.id)

			if (element && element.$circleObject) {
				let new_center = {
					lat: element.$circleObject.getCenter().lat(),
					lng: element.$circleObject.getCenter().lng()
				}

				//Vérifie si les nouvelles valeurs sont différentes de celles mémorisées
				let old_values = this.$store.state.annotations.elements.find(e => e.id == circle.id)
				if (!_.isEqual(new_center, old_values.center)) {
					let new_values = {
						id: circle.id,
						center: new_center
					}
					this.$store.dispatch('updateAnnotationsElement', new_values)
				}
			}
		}, 300),

		//AS--> Cercle : modification du rayon
		handleRadiusChanged: _.debounce(function (circle) {
			//Trouve l'élément sur la carte par son id
			const element = this.getElement(circle.id)
			if (element && element.$circleObject) {
				let new_radius = element.$circleObject.getRadius()
				this.$store.dispatch('updateAnnotationsElement', {
					id: circle.id,
					radius: new_radius
				})
			}
		}, 300),

		//AS--> Rectangle : modification des coins
		handleBoundsChanged: _.debounce(function (rectangle) {
			//Trouve l'élément sur la carte par son id
			const element = this.getElement(rectangle.id)
			if (element && element.$rectangleObject) {
				const bounds = element.$rectangleObject.getBounds();
				const new_bounds = {
					north: bounds.getNorthEast().lat(),
					east: bounds.getNorthEast().lng(),
					south: bounds.getSouthWest().lat(),
					west: bounds.getSouthWest().lng(),
				}
				if(!_.isEqual(new_bounds, rectangle.bounds)){
					this.$store.dispatch('updateAnnotationsElement', {
						id: rectangle.id,
						bounds: new_bounds
					})
				}
			}
		}, 300),

		//AS--> Marqueur : déplacement
		handleDragendMarker: _.debounce(function (marker) {

			//Trouve l'élément sur la carte par son id
			const element = this.getElement(marker.id)
			if (element && element.$markerObject) {
				let new_position = {lat: element.$markerObject.getPosition().lat(), lng: element.$markerObject.getPosition().lng()}
				if(new_position.lat != marker.position.lat || new_position.lng != marker.position.lng){
					this.$store.dispatch('updateAnnotationsElement', {
						id: marker.id,
						position: new_position
					})
				}
			}
		}, 300),

		//AS--> Polygone : déplacement
		handleDragendPolygon: _.debounce(function (polygon) {
			//Trouve l'élément sur la carte par son id
			const element = this.getElement(polygon.id)
			if (element && element.$polygonObject) {
				let new_paths = element.$polygonObject.getPath().getArray()
				let new_paths_array = new_paths.map(path => ({
					lat: path.lat(),
					lng: path.lng()
				}))
				this.$store.dispatch('updateAnnotationsElement', {
					id: polygon.id,
					paths: new_paths_array
				})
			}
		}, 300),

		//AS--> Polyligne : déplacement
		handleDragendPolyline: _.debounce(function (polyline) {
			//Trouve l'élément sur la carte par son id
			const element = this.getElement(polyline.id)
			if (element && element.$polylineObject) {
				let new_paths = element.$polylineObject.getPath().getArray()
				let new_paths_array = new_paths.map(path => ({
					lat: path.lat(),
					lng: path.lng()
				}))
				this.$store.dispatch('updateAnnotationsElement', {
					id: polyline.id,
					paths: new_paths_array
				})
			}
		}, 300),

		//AS--> Texte : déplacement
		handleDragendText: _.debounce(function (text) {
			//Trouve l'élément sur la carte par son id
			const element = this.getElement(text.id)
			if (element && element.$textObject) {
				let new_position = element.$textObject.getPosition()
				this.$store.dispatch('updateAnnotationsElement', {
					id: text.id,
					position: new_position
				})
			}
		}, 300),

		handleDeleteElement() {
			//AS--> Pour chaque élément sur la carte qui est en mode editable, on le supprime
			this.$store.state.annotations.elements.forEach(element => {
				//Trouve l'élément sur la carte par son id
				const forme = this.getElement(element.id)

				//Vérifie si l'élément est selected dans le store et si oui, on le supprime
				if (element.selected) {
					this.$store.dispatch('deleteAnnotationsElement', element.id)
				}
			})
		},

		//AS--> Active ou désactive la sélection de coordonnées et mémorise le type de dessin qui l'a demandé
		switch_map_get_coords(type){
			this.get_coords = this.get_coords == type ? false : type;
			// this.$store.dispatch('set_map_coords', this.get_coords);
		},

	},



	computed: {
		getCircleOptions() {
			return (circle) => ({
				strokeWeight: circle.strokeWeight,
				strokeOpacity: circle.strokeOpacity,
				strokeColor: circle.strokeColor,
				fillOpacity: circle.fillOpacity,
				fillColor: circle.fillColor,
				draggable: true,
				clickable: this.getClickable,
				editable: circle.selected,
				zIndex: circle.zindex + this.zIndexBase
			})
		},
		getRectangleOptions() {
			return (rectangle) => ({
				strokeWeight: rectangle.strokeWeight,
				strokeOpacity: rectangle.strokeOpacity,
				strokeColor: rectangle.strokeColor,
				fillOpacity: rectangle.fillOpacity,
				fillColor: rectangle.fillColor,
				draggable: true,
				clickable: this.getClickable,
				editable: rectangle.selected,
				zIndex: rectangle.zindex + this.zIndexBase
			})
		},
		getPolygonOptions() {
			return (polygon) => ({
				strokeWeight: polygon.strokeWeight,
				strokeOpacity: polygon.strokeOpacity,
				strokeColor: polygon.strokeColor,
				fillOpacity: polygon.fillOpacity,
				fillColor: polygon.fillColor,
				draggable: true,
				clickable: this.getClickable,
				editable: polygon.selected,
				zIndex: polygon.zindex + this.zIndexBase
			})
		},
		getPolylineOptions() {
			return (polyline) => ({
				strokeWeight: polyline.strokeWeight,
				strokeOpacity: polyline.strokeOpacity,
				strokeColor: polyline.strokeColor,
				draggable: true,
				clickable: this.getClickable,
				editable: polyline.selected,
				zIndex: polyline.zindex + this.zIndexBase
			})
		},
		getMarkerOptions() {
			return (marker) => ({
				clickable: this.getClickable,
				draggable: this.getClickable,
				zIndex: marker.zindex + this.zIndexBase + 1000
			})
		},
		getTextOptions() {
			return (text) => ({
				clickable: this.getClickable,
				draggable: true,
				fontColor: text.fontColor,
				fontSize: `${text.fontSize}px`,
				fontWeight: text.fontWeight || 'normal',
				fontStyle: text.fontStyle || 'normal',
				strokeWeight: text.strokeWeight,
				strokeOpacity: text.strokeOpacity,
				strokeColor: text.strokeColor,
				fillOpacity: text.fillOpacity,
				fillColor: text.fillColor,
				zIndex: text.zindex + 5000
			})
		},
		getClickable() {
			return this.$store.state.ui.modale_gauche == 'OutilAnnoter'
		},
		getInfoWindow() {
			console.log('getInfoWindow')
			const selectedElement = this.$store.state.annotations.elements.find(element => element.selected)

			setTimeout(() => {
				if (!selectedElement){
					this.infoWindowInfos = null
					return
				}


				const position = this.getHighestPoint(selectedElement)
				if (!position){
					this.infoWindowInfos = null
					return
				}

				let verticalOffset = -10 // Décalage par défaut

				// Si c'est un marker, on utilise l'anchor du picto pour calculer le décalage
				if (selectedElement.type === 'marker' && selectedElement.icon) {
					const picto = this.$store.state.annotations.pictos[selectedElement.icon]
					if (picto && picto.anchor) {
						// On ajoute 10px de marge supplémentaire au-dessus de l'anchor
						verticalOffset = -(picto.anchor.y + 18)
					}
				}
				
				// Pour les éléments de type texte, on accède directement au DOM
				if (selectedElement.type === 'text') {
					// Récupération de l'élément DOM via sa référence
					const textElement = this.$refs[selectedElement.id]?.[0]

					if(textElement && textElement?.$gmapTextObject?.div){
						verticalOffset = -(textElement.$gmapTextObject.div.offsetHeight + 10)
					}
				}
				
				if(selectedElement.type === 'marker') {
					// verticalOffset dépend de la taille du texte et de la position du picto
					let decalage_picto = this.$store.state.annotations.pictos[selectedElement.icon].anchor.y + 10
					verticalOffset = -selectedElement.fontSize - decalage_picto
				}

				this.infoWindowInfos = {
					options: {
						pixelOffset: {
							width: 0,
							height: verticalOffset
						}
					},
					position,
					opened: true,
					element: selectedElement,
					component: 'Annotation' + selectedElement.type.charAt(0).toUpperCase() + selectedElement.type.slice(1),
					class: 'annotation-' + selectedElement.type,
					zIndex: 10000000
				}
			}, 50)
		}
	}
}