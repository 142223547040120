import menu_sous_menu from '../../../common/ui/menu_sous-menu.vue'

export default {
    name: 'AnnotationFormForme',
    data() {
        return {
            menuSize: {
                "menu": {
                    "label": this.element.strokeWeight+'pt',
                    "arrow": false
                },
                "sousmenu": {
                    "list": [
                        {
                            label: "1pt",
                            action: () => { this.updateStrokeWeight(1) }
                        },
                        {
                            label: "2pt",
                            action: () => { this.updateStrokeWeight(2) }
                        },
                        {
                            label: "3pt",
                            action: () => { this.updateStrokeWeight(3) }
                        },
                        {
                            label: "4pt",
                            action: () => { this.updateStrokeWeight(4) }
                        },
                        {
                            label: "5pt",
                            action: () => { this.updateStrokeWeight(5) }
                        },
                        {
                            label: "6pt",
                            action: () => { this.updateStrokeWeight(6) }
                        },
                        {
                            label: "7pt",
                            action: () => { this.updateStrokeWeight(7) }
                        },
                        {
                            label: "8pt",
                            action: () => { this.updateStrokeWeight(8) }
                        },
                        {
                            label: "9pt",
                            action: () => { this.updateStrokeWeight(9) }
                        },
                        {
                            label: "10pt",
                            action: () => { this.updateStrokeWeight(10) }
                        }
                    ]
                }
            },
            menuOpacity: {
                "menu": {
                    "label": this.mode === 'fill' ? this.element.fillOpacity*100+'%' : this.element.strokeOpacity*100+'%',
                    "arrow": false
                },
                "sousmenu": {
                    "list": [
                        {
                            label: "0%",
                            action: () => { this.updateFillOpacity(0)}
                        },
                        {
                            label: "10%",
                            action: () => { this.updateFillOpacity(0.1)}
                        },
                        {
                            label: "20%",
                            action: () => { this.updateFillOpacity(0.2)}
                        },
                        {
                            label: "30%",
                            action: () => { this.updateFillOpacity(0.3)}
                        },
                        {
                            label: "40%",
                            action: () => { this.updateFillOpacity(0.4) }
                        },
                        {
                            label: "50%",
                            action: () => { this.updateFillOpacity(0.5) }
                        },
                        {
                            label: "60%",
                            action: () => { this.updateFillOpacity(0.6) }
                        },
                        {
                            label: "70%",
                            action: () => { this.updateFillOpacity(0.7) }
                        },
                        {
                            label: "80%",
                            action: () => { this.updateFillOpacity(0.8) }
                        },
                        {
                            label: "90%",
                            action: () => { this.updateFillOpacity(0.9) }
                        },
                        {
                            label: "100%",
                            action: () => { this.updateFillOpacity(1) }
                        }
                    ]
                }
            }
        }
    },
    props: {
        element: {
            type: Object,
            required: true
        },
        mode: {
            type: String,
            required: true
        },
        showOpacity: {
            type: Boolean,
            default: true
        },
        showStrokeWeight: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        updateElement() {
            let properties = {
                id: this.element.id
            }
            if(this.mode === 'stroke') {
                properties.strokeColor = this.element.strokeColor
                if(this.showOpacity) {
                    properties.strokeOpacity = this.element.strokeOpacity
                }
                if(this.showStrokeWeight) {
                    properties.strokeWeight = this.element.strokeWeight
                }
            } else {
                properties.fillColor = this.element.fillColor
                if(this.showOpacity) {
                    properties.fillOpacity = this.element.fillOpacity
                }
            }
            this.$store.dispatch('updateAnnotationsElement', properties)
        },
        updateStrokeWeight(weight) {
            this.element.strokeWeight = weight
            this.menuSize.menu.label = weight+'pt'
            this.updateElement()
        },
        updateFillOpacity(opacity) {
            if(this.mode === 'fill') {
                this.element.fillOpacity = opacity
                this.menuOpacity.menu.label = opacity*100+'%'
            } else {
                this.element.strokeOpacity = opacity
                this.menuOpacity.menu.label = opacity*100+'%'
            }

            this.updateElement()
        }
    },
    components: {
        menu_sous_menu
    }
}
